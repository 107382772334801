import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext"; // 사용자의 인증 정보를 가져오기 위한 컨텍스트 사용

// 스타일 컴포넌트 정의
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 250px;
  width: calc(100% - 250px);
`;

const VerticalContainer = styled.div`
  display: flex;
  margin-left: 250px;
  flex-direction: column;  // This will stack children vertically
  width: calc(100% - 250px);
`;

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;  // Keeps sections spaced evenly
  width: 100%;  // Use the full width
  margin-left: 10px;
  background-color: #F4F4F4;
`;

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 400px;
  margin: 10px;
  border-radius: 10px;
  width: ${props => props.width || '45%'};
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
  tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 3px;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Label1 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  flex: 0 0 100px;
`;

const Label2 = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  color: #495057; // Darker grey for better readability
`;


const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;  // Aligns items vertically in the center
  margin-bottom: 2px;
`;

const Value = styled.span`
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 12px;
  color: #333; // Dark gray for better readability
  display: inline-block;
  flex-grow: 1;
`;

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  height: 70%;
  max-height: 80%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column; /* 상단은 고정하고, 하단을 스크롤 가능하게 */
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  flex-grow: 1; /* 남은 공간을 차지하여 스크롤 가능하게 */
  margin-top: 20px;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background-color: #ff5c5c;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: #e60000;
  }
`

const Dropdown = styled.select`
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
  font-size: 14px;
`;

const Button = styled.button`
  background-color: #a24efc; 
  color: white;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;
  
  &:hover {
    background-color: #8e3fe3; 
  }
`;



const ReportList = () => {
  const { currentUser } = useAuth(); // 현재 사용자의 인증 정보 가져오기
  const [reports, setReports] = useState([]);
  const [error, setError] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // 팝업 상태 관리
  const [chatLogs, setChatLogs] = useState([]); // 빈 배열로 초기화
  const [banDays, setBanDays] = useState(0); // 제재 기간 설정 (기본값 1일)
  const itemsPerPage = 10;

  // 서버에서 데이터 가져오기 또는 더미 데이터 사용
  useEffect(() => {
    const fetchReports = async () => {
      if (!currentUser) {
        setError("No user logged in.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_DNS}/api/admin/getReports/${currentUser.adminId}`,
          {},
          {
            headers: {
              'x-access-token': currentUser.accessToken
            }
          }
        );

        if (response.status === 200) {
          setReports(response.data.reports.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        } else {
          setError("Failed to fetch reports.");
        }
      } catch (error) {
        const status = error.response ? error.response.status : 500;
        const message = error.response ? error.response.data.message : "Network or server error.";
        setError(`Fetch failed: ${message} (Status: ${status})`);
      }
    };

    fetchReports();
  }, [currentUser]);

  // 페이지 이동 핸들러
  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < reports.length) {
      setCurrentPage(currentPage + 1);
      setSelectedReport(null);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setSelectedReport(null);
    }
  };

  const handleReportClick = (report) => {
    setSelectedReport(report); // 선택된 신고 설정
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${date.getHours()}시 ${date.getMinutes()}분`;
  };

  // 채팅 내역 불러오기
  const fetchChatLogs = async (reportedUserId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DNS}/api/admin/user-chat-list/${currentUser.adminId}`,
        { userId: reportedUserId },
        {
          headers: {
            'x-access-token': currentUser.accessToken,
          },
        }
      );

      if (response.status === 200) {
        setChatLogs(response.data.messages || []);
        setIsPopupOpen(true); // 팝업 열기
      } else {
        setError("Failed to fetch chat logs.");
      }
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const message = error.response ? error.response.data.message : "Network or server error.";
      setError(`Failed to fetch chat logs: ${message} (Status: ${status})`);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false); // 팝업 닫기
    setChatLogs([]); // 채팅 로그 초기화
  };
  const handleBanUser = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DNS}/api/admin/user-ban/${currentUser.adminId}`,
        { days: banDays }, // 선택된 제재 기간을 body에 포함
        {
          headers: {
            'x-access-token': currentUser.accessToken
          },
          params: { id: selectedReport.reportedDetails.id } // 유저 ID를 쿼리 파라미터로 전송
        }
      );

      if (response.status === 200) {
        alert('유저 제재가 성공적으로 적용되었습니다.');
      } else {
        setError('유저 제재에 실패했습니다.');
      }
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const message = error.response ? error.response.data.message : 'Network or server error.';
      setError(`유저 제재 실패: ${message} (Status: ${status})`);
    }
  };


  const handleBanResetUser = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DNS}/api/admin/user-ban-reset/${currentUser.adminId}`, {},
        {
          headers: {
            'x-access-token': currentUser.accessToken
          },
          params: { id: selectedReport.reportedDetails.id } // 유저 ID를 쿼리 파라미터로 전송
        }
      );

      if (response.status === 200) {
        alert('유저 제재가 성공적으로 해제되었습니다.');
      } else {
        alert('유저 제재 해제에 실패했습니다.');
      }
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const message = error.response ? error.response.data.message : 'Network or server error.';
      setError(`유저 제재 해제 실패: ${message} (Status: ${status})`);
    }
  };

  // 현재 페이지에 맞는 데이터 슬라이스
  const indexOfFirstReport = currentPage * itemsPerPage;
  const indexOfLastReport = indexOfFirstReport + itemsPerPage;
  const currentReports = reports.slice(indexOfFirstReport, indexOfLastReport);

  return (
    <VerticalContainer>
      <HorizontalContainer>
        <Section width="35%" id="tableSection">
          <Title>신고 목록 보기</Title>
          <Divider />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Table>
            <thead>
              <tr>
                <th>일자</th>
                <th>신고 유형</th>
                <th>신고자</th>
                <th>피신고자</th>
              </tr>
            </thead>
            <tbody>
              {currentReports.map((report) => (
                <tr key={report.id} onClick={() => handleReportClick(report)}>
                  <td>{new Date(report.createdAt).toLocaleDateString()}</td>
                  <td>{report.reportType}</td>
                  <td>{report.reporterDetails.nickName}</td>
                  <td>{report.reportedDetails.nickName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Navigation>
            <NavButton onClick={handlePreviousPage} disabled={currentPage === 0}>
              이전
            </NavButton>
            <NavButton onClick={handleNextPage} disabled={indexOfLastReport >= reports.length}>
              다음
            </NavButton>
          </Navigation>
        </Section>
        <Section width="60%" id="detailSection">
          {selectedReport ? (
            <div>
              <Title>신고 내역 상세</Title>
              <Divider />
              <DetailRow>
                <Label1>신고 일시 </Label1>
                <Value>{formatDate(selectedReport.createdAt)}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>신고자 </Label1>
                <Value>{selectedReport.reporterDetails.nickName} | {selectedReport.reporterDetails.name} | {selectedReport.reporterDetails.phoneNumber}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>피신고자 </Label1>
                <Value>{selectedReport.reportedDetails.id} | {selectedReport.reportedDetails.name} | {selectedReport.reportedDetails.phoneNumber}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>신고 유형 </Label1>
                <Value>{selectedReport.reportType}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>신고 내용 </Label1>
                <Value>{selectedReport.reportContent}</Value>
              </DetailRow>
              <Divider />
              <DetailRow>
                <Button onClick={() => fetchChatLogs(selectedReport.reportedDetails.id)}>
                  채팅 내역 보기
                </Button>
                <h2>제재 기간</h2>
                <Dropdown value={banDays} onChange={(e) => setBanDays(parseInt(e.target.value))}>
                  <option value={1}>1일</option>
                  <option value={2}>2일</option>
                  <option value={3}>3일</option>
                  <option value={4}>4일</option>
                  <option value={5}>5일</option>
                  <option value={6}>6일</option>
                  <option value={7}>7일</option>
                  <option value={1000}>영구 정지</option>
                </Dropdown>
                <Button onClick={handleBanUser}>제재하기</Button>
                <Button onClick={handleBanResetUser}>제재 해제하기</Button>
              </DetailRow>
            </div>
          ) : (
            <p>신고 항목을 선택해 주세요.</p>
          )}
        </Section>
      </HorizontalContainer>

      {/* 팝업 창 */}
      {isPopupOpen && (
        <Popup>
          <PopupContent>
            <PopupHeader>
              <h2>채팅 내역</h2>
              <CloseButton onClick={closePopup}>닫기</CloseButton>
            </PopupHeader>
            <ScrollableContent>
              <ul>
                {chatLogs.length > 0 ? (
                  chatLogs.map((log, index) => (
                    <li key={index} style={{ marginTop: '5px' }}>
                      <strong>{formatDate(log.createdAt)}: </strong>
                      {log.content}
                    </li>
                  ))
                ) : (
                  <li>채팅 내역이 없습니다.</li>
                )}
              </ul>
            </ScrollableContent>
          </PopupContent>
        </Popup>
      )
      }
    </VerticalContainer >
  );
};

export default ReportList;
