import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

// 스타일 컴포넌트 정의
const VerticalContainer = styled.div`
  display: flex;
  margin-left: 250px;
  flex-direction: column;  // This will stack children vertically
  width: calc(100% - 250px);
`;

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;  // Keeps sections spaced evenly
  width: 100%;  // Use the full width
  margin-left: 10px;
  background-color: #F4F4F4;
`;

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 400px;
  margin: 10px;
  border-radius: 10px;
  width: ${props => props.width || '45%'};
`;

const UserInfoSection = styled(Section)`
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  margin: 10px;
  max-height: 100px;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
  tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 3px;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const FormGroup = styled.div`
display: flex;
flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // Subtle shadow for depth
`;

const Label1 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  flex: 0 0 100px;
`;

const Label2 = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  color: #495057; // Darker grey for better readability
`;

const TextArea = styled.textarea`
  width: 97%;
  height: 100px;
  padding: 8px 10px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #ced4da; // Light grey border
  border-radius: 4px;
  resize: none;
  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
`;

const SubmitButton = styled.button`
  background-color: ${props => props.disabled ? '#6c757d' : '#9C41FF'}; // Darker when disabled
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-size: 14px;
  display: block; // Ensures it takes its own line
  width: 100%; // Makes the button stretch to fill the container
  &:hover {
    background-color: ${props => props.disabled ? '#6c757d' : '#633199'};
  }
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;  // Aligns items vertically in the center
  margin-bottom: 2px;
`;

const Value = styled.span`
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 12px;
  color: #333; // Dark gray for better readability
  display: inline-block;
  flex-grow: 1;
`;

const InquiryList = () => {
  const { currentUser } = useAuth();
  const [inquiries, setInquiries] = useState([]);
  const [error, setError] = useState("");
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [answer, setAnswer] = useState("");
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchInquiries = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_DNS}/api/admin/getInquiries/${currentUser.adminId}`,
            {
              refreshToken: currentUser.refreshToken,
              accessToken: currentUser.accessToken
            }
          );

          if (response.status === 200) {
            setInquiries(response.data.inquiries.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
          } else {
            setError("Failed to fetch inquiries");
          }
        } catch (error) {
          setError("Error Detected: " + (error.response ? error.response.data.message : error.message));
        }
      }
    };

    fetchInquiries();
  }, [currentUser]);

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < inquiries.length) {
      setCurrentPage(currentPage + 1);
      setSelectedInquiry(null); // 페이지 변경 시 선택된 문의 초기화
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setSelectedInquiry(null); // 페이지 변경 시 선택된 문의 초기화
    }
  };

  const handleInquiryClick = (inquiry) => {
    setSelectedInquiry(inquiry); // 선택된 문의 설정
    setAnswer(inquiry.answer || ""); // 답변 초기화
  };

  const handleAnswerSubmit = async () => {
    if (!answer.trim()) {
      alert("답변을 입력해주세요.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DNS}/api/admin/answerInquiry/${currentUser.adminId}?id=${selectedInquiry.id}`,
        { answer },
        {
          headers: {
            'x-access-token': currentUser.accessToken
          }
        }
      );

      if (response.status === 200) {
        alert("답변이 성공적으로 등록되었습니다.");
        // Update the inquiries list in state to reflect the answered inquiry
        setInquiries(prevInquiries =>
          prevInquiries.map(inquiry =>
            inquiry.id === selectedInquiry.id ? { ...inquiry, answer } : inquiry
          )
        );
        setSelectedInquiry({ ...selectedInquiry, answer });  // Update the selected inquiry in state
      } else {
        setError("Failed to submit answer");
      }
    } catch (error) {
      setError(`Error submitting answer: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    const [time] = timePart.split('.');
    const formattedString = `${year}년 ${month}월 ${day}일 ${time} GMT+0900 (한국표준시)`;

    return formattedString;
  };

  const indexOfFirstInquiry = currentPage * itemsPerPage;
  const indexOfLastInquiry = Math.min(indexOfFirstInquiry + itemsPerPage, inquiries.length);
  const currentInquiries = inquiries.slice(indexOfFirstInquiry, indexOfLastInquiry);

  return (
    <VerticalContainer>
      <HorizontalContainer>
        <Section width="35%" id="tableSection">
          <Title>문의 목록 보기</Title>
          <Divider />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Table>
            <thead>
              <tr>
                <th>일자</th>
                <th>이름</th>
                <th>제목</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              {inquiries.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((inquiry) => (
                <tr key={inquiry.id} onClick={() => handleInquiryClick(inquiry)}>
                  <td>{new Date(inquiry.createdAt).toLocaleDateString()}</td>
                  <td>{inquiry.name}</td>
                  <td>{inquiry.title}</td>
                  <td>{inquiry.content.substring(0, 20)}...</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Navigation>
            <NavButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
              이전
            </NavButton>
            <NavButton onClick={() => setCurrentPage(currentPage + 1)} disabled={(currentPage + 1) * itemsPerPage >= inquiries.length}>
              다음
            </NavButton>
          </Navigation>
        </Section>
        <Section width="60%" id="detailSection">
          {selectedInquiry ? (
            <div>
              <Title>문의 내역 상세</Title>
              <Divider />
              <DetailRow>
                <Label1>문의 일자 </Label1>
                <Value>{formatDate(selectedInquiry.createdAt)}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>문의자 </Label1>
                <Value>{selectedInquiry.nickName} | {selectedInquiry.name} | {selectedInquiry.phoneNumber}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>문의 제목 </Label1>
                <Value>{selectedInquiry.title}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>문의 내용 </Label1>
                <Value>{selectedInquiry.content}</Value>
              </DetailRow>
              <FormGroup>
                <Label2 htmlFor="answer">답변 작성</Label2>
                <TextArea
                  id="answer"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  readOnly={!!selectedInquiry.answer}
                />
                <SubmitButton onClick={handleAnswerSubmit} disabled={!!selectedInquiry.answer}>
                  {selectedInquiry.answer ? "답변완료" : "답변하기"}
                </SubmitButton>
              </FormGroup>
            </div>
          ) : (
            <p>문의 항목을 선택해 주세요.</p>
          )}
        </Section>
      </HorizontalContainer>
    </VerticalContainer>
  );
};
export default InquiryList;